.data-value,
.data-label {
    margin-bottom: 2px;
    font-size: 1rem;
    text-align: center;
    padding: 5px 0px;
    border-radius: 5px;
}

.data-label:first-child {
    margin-top: 30px;
}

.data-label {
    font-size: 0.75rem;
    padding: 0.52rem 0;
    white-space: pre;
}

.data-value.success {
    background-color: #d4edda;
    border-color: #c3e6cb;
    color: #155724;
}

.data-value.danger {
    background-color: #f8d7da;
    border-color: #f5c6cb;
    color: #721c24;
}

.data-value.light {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}
