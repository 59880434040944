.main_footer {
	color: #929595;
	background: #2f3337;
}

.main_footer .inner {
	max-width: 980px;
	margin: 0 auto;
	padding: 10px 0px 10px 0px;
}

.main_footer .upper {
	display: block;
	padding: 35px 0 10px 0;
}

.main_footer .extra {
	display: block;
	padding: 5px 0 7px 0;
	text-align: center;
	background-size: 50% auto;
}

.main_footer .extra a {
	margin: 0 auto;
	font-size: unset;
	text-decoration: underline;
}

.main_footer .extra a img {
	opacity: 0.5;
	margin-right: 15px;
}

.main_footer .extra a:hover img {
	opacity: 1;
}

.main_footer .upper .inner {
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	padding-bottom: 40px;
}

.main_footer .upper_footer_left {
	float: left;
	text-align: right;
	width: 49%;
	border-right: 1px solid rgba(255, 255, 255, 0.1);
	padding-right: 9px;
}

.main_footer .upper p {
	font-size: 16px;
	margin-top: 16px;
	margin-bottom: 5px;
	color: #fff;
}

.main_footer .upper label {
	font-size: 12px;
	font-weight: 400;
	letter-spacing: 1px;
	margin: 0;
}

.main_footer .upper small {
	font-size: 13px;
}

.main_footer .upper_footer_right {
	float: right;
	text-align: left;
	width: 49%;
}

.main_footer .lower {
	padding: 16px 0;
	width: 100%;
	display: block;
	background: rgba(255, 255, 255, 0.05);
}

.main_footer ul {
	padding: 0;
	margin: 0;
	list-style: none;
}

.main_footer ul.footer_sitemap {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: flex-start;
	flex-wrap: wrap;
}

.main_footer ul.footer_sitemap li.sitemap_block {
	display: inline-block;
	width: 19%;
	padding: 0;
	margin: 0;
	text-align: left;
}

.main_footer ul.footer_sitemap li.sitemap_block ul li {
	margin-bottom: 5px;
}

.main_footer ul.footer_sitemap li.sitemap_block ul li.icon {
	margin-bottom: 25px;
}

.main_footer ul.footer_sitemap li.sitemap_block.social {
	text-align: left;
	width: 20%;
}

.main_footer ul.footer_sitemap li.sitemap_block.social a {
	width: 150px;
	margin-top: 5px;
	margin-left: 50px;
}

.main_footer
	ul.footer_sitemap
	li.sitemap_block.social
	li:first-of-type
	a {
	margin-top: 0px;
}

.main_footer a {
	color: #929595 !important;
	font-size: 15px;
	text-decoration: underline !important;
}

.main_footer a:hover {
	color: #fff;
}

.main_footer ul.footer_sitemap li.sitemap_block ul li.sitemap_heading {
	color: #ffffff !important;
	font-family: National-SemiBold, Calibri, "Helvetica Neue", Helvetica, Arial,
		sans-serif;
	text-transform: uppercase;
	margin-bottom: 10px;
}

.sitemap_heading a {
    text-decoration: none !important;
	color: #fff !important;
}

.sitemap_block button {
    background: none;
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: underline;
    color:rgb(146, 149, 149);   
    font-size: 15px;
}

.sitemap_block button:hover {
    cursor: pointer;
    color: #fff;
}

#social-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0px;
}

#social-icons a:first-child {
	margin-right: 15px;
}

@media all and (max-width: 900px) {
    .footer_sitemap .sitemap_block {
        width: 50% !important;
        text-align: center !important;
        margin-bottom: 25px !important;
        min-width: 150px !important;
    }   
}