/*** Apply to everything ***/
* {
	font-family: 'DM Serif Display';
}

*:focus {
    outline: none !important;
    box-shadow: none !important;
}

/*** Parent containers ***/
html,
body,
#root,
.App,
.page-wrapper {
	width: 100%;
	height: 100%;
	margin: 0px;
	padding: 0px;
	overflow-x: hidden;
}

.page-wrapper {
	display: flex;
	flex-direction: column;
	position: relative;
}

.hide {
	display: none;
}

select {
    padding: .3125rem 3rem .3125rem 1rem;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat, repeat;
    background-position: right 1rem center;
    border-radius: .25rem;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: 1px solid #d8e2ef;
    color: #344040;
    background-size: 10px 12px;
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(0,0,0,0.075);
    outline:none;
}

.dropdown-toggle::after {
    display: none !important;
}

/* ListGroup */
.labelValueListGroup .list-group-item {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
}

.labelValueListGroup.justify-content-row .list-group-item {
    flex-direction: row !important;
    align-items: center !important;
}

.labelValueListGroup label {
    width: auto;
    display: block;
    margin-bottom: 0px;
    font-weight: 600;
    margin-right: auto;
    font-size: 0.95rem;
}

.labelValueListGroup p {
    font-size: 14px;
}

.text-danger {
    color: rgb(114, 28, 36) !important;
}

.text-success {
    color: rgb(21, 87, 36) !important;
}