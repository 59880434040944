
.forgot-wrapper {
    margin-top: 15px;
    text-align: center;
}

.forgot-wrapper button {
    color: #027dc5;
    line-height: normal;
    background: none;
    border: none;
	padding: 0px;
	text-decoration: none;
    position: absolute;
    right: 25px;
    font-size: 14px !important;
}

.error {
	color: #da1716;
	text-align: center;
	line-height: 18px;
	display: block;
	margin-bottom: 10px;
}

.forgot-wrapper p {
	font-size: 0.75rem;
    color: rgba(36,28,21,0.65);
    line-height: 1.5;
}